*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-left-0 {
  left: 0;
}

.tw-top-0 {
  top: 0;
}

.tw-m-0 {
  margin: 0;
}

.-tw-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-tw-mb-\[10\%\] {
  margin-bottom: -10%;
}

.-tw-mt-\[10\%\] {
  margin-top: -10%;
}

.tw-mb-1 {
  margin-bottom: .25rem;
}

.tw-mb-3 {
  margin-bottom: .75rem;
}

.tw-ml-3 {
  margin-left: .75rem;
}

.tw-ml-\[5\%\] {
  margin-left: 5%;
}

.tw-mr-2 {
  margin-right: .5rem;
}

.tw-mt-1 {
  margin-top: .25rem;
}

.tw-mt-2 {
  margin-top: .5rem;
}

.tw-mt-3 {
  margin-top: .75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-flex {
  display: flex;
}

.tw-grid {
  display: grid;
}

.tw-hidden {
  display: none;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-full {
  height: 100%;
}

.tw-max-h-\[100px\] {
  max-height: 100px;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-\[30\%\] {
  width: 30%;
}

.tw-w-\[45\%\] {
  width: 45%;
}

.tw-w-\[auto\] {
  width: auto;
}

.tw-w-full {
  width: 100%;
}

.tw-flex-none {
  flex: none;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-evenly {
  justify-content: space-evenly;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-lg {
  border-radius: .5rem;
}

.tw-rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.tw-border-b-0 {
  border-bottom-width: 0;
}

.tw-border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.tw-bg-\[\#f9e0fe\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 224 254 / var(--tw-bg-opacity));
}

.tw-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.tw-bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.tw-p-0 {
  padding: 0;
}

.tw-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.tw-px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tw-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tw-pt-10 {
  padding-top: 2.5rem;
}

.tw-text-center {
  text-align: center;
}

.tw-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-text-\[\#9e3701\] {
  --tw-text-opacity: 1;
  color: rgb(158 55 1 / var(--tw-text-opacity));
}

.tw-text-\[\#c76e00\] {
  --tw-text-opacity: 1;
  color: rgb(199 110 0 / var(--tw-text-opacity));
}

.tw-text-\[\#f8d1ff\] {
  --tw-text-opacity: 1;
  color: rgb(248 209 255 / var(--tw-text-opacity));
}

.tw-text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.tw-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

body {
  min-height: 100vh;
  background-color: #0000;
  background-image: linear-gradient(#101332b3 0%, #0a0b0d 70%), url("bg.c40f81fe.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

html, body {
  font-family: Inter, Arial, Helvetica, sans-serif;
}

.btn-playgame {
  color: #fff;
  background: linear-gradient(to right, #8551ff, #fb5bc8, #fb999a);
  border: 0;
  border-radius: 24px;
}

[data-bs-theme="dark"] .shadow-inside {
  background-image: radial-gradient(#282145, #0000 5%, #2f2e4c);
}

.section-title {
  background: #151632;
  border-radius: 99px;
  padding: .05em 1em;
  position: relative;
  box-shadow: inset 0 0 5px 1px #fff3;
}

.btn-more {
  width: 100%;
  height: 100%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#1e579900", endColorstr= "#262835", GradientType= 0);
  background: linear-gradient(#1e579900 0%, #262835 100%);
  justify-content: center;
  align-items: end;
  display: flex;
}

.game-info {
  max-height: 4.75em;
  transition: all .3s ease-in-out;
  display: none;
}

.game-description {
  opacity: .5;
}

.game-info--open .game-info {
  max-height: unset;
  display: block;
}

.game-info--open .game-info .btn-more {
  display: none;
}

.game-info--open .game-description {
  opacity: 1;
}

.custom-modal {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#feda3e", endColorstr= "#f8b81a", GradientType= 0);
  background: linear-gradient(#feda3e 0%, #f8b81a 100%);
  border: 5px solid #ef8405;
  border-radius: 20px;
  box-shadow: inset 0 0 1px 3px #da820d;
}

.bg-darkarea {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#962f01", endColorstr= "#af4e01", GradientType= 0);
  background: linear-gradient(#962f01 0%, #9f3e01 33%, #af4e01 100%);
  border: 2px solid #ef8405;
  border-radius: 20px;
  padding: 15px;
  box-shadow: inset 0 0 1px 5px #f6bd3b;
}

.game-item {
  width: 50%;
  transition: all .3s ease-in-out;
}

.game-info--open.game-item {
  width: 100%;
}

.game-item-bg {
  width: 100%;
  transition: all .3s ease-in-out;
}

.game-info--open.game-item .game-item-bg {
  width: 50%;
}

.game-item:nth-child(odd) {
  padding-right: .4em;
}

.game-item:nth-child(2n) {
  padding-left: .4em;
}

.game-item .card {
  background: none;
  border: 0;
}

.game-item .game-item-bg {
  min-height: 110%;
  background: url("bg-sm-circle.de31dbd0.png") 0 / 55% no-repeat;
  border: 0;
  padding: 5% 0;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.tw-group.tw-no-description .group-\[\.no-description\]\:tw-hidden {
  display: none;
}

/*# sourceMappingURL=index.7631d7ee.css.map */
