@tailwind base;
@tailwind components;
@tailwind utilities;

/* [data-bs-theme="dark"] html,
[data-bs-theme="dark"] body {
    background: linear-gradient(#101332, #0A0B0D);
    background: url(./bg/2125.jpg);
} */

body {
    min-height: 100vh;
    background: linear-gradient(rgb(16 19 50 / 70%) 0%, rgb(10 11 13) 70%), url(./ui/bg.jpg);
    background-size: auto 100%;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

html,
body {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.btn-playgame {
    background: linear-gradient(to right, #8551ff, #fb5bc8, #fb999a);
    border-radius: 24px;
    color: #fff;
    border: 0;
}

[data-bs-theme="dark"] .shadow-inside {
    background-image: radial-gradient(#282145, transparent 5%, #2f2e4c);
}

.section-title {
    position: relative;
    /* padding-left: 10px; */
    background: #151632;
    border-radius: 99px;
    padding: 0.05em 1em;
    box-shadow: inset 0px 0px 5px 1px rgb(255 255 255 / 20%);
}

/* .section-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: #ff9b3a;
} */

.btn-more {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
    background: #262835;
    /* Old browsers */
    background: -moz-linear-gradient(top, #1e579900 0%, #262835 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #1e579900 0%, #262835 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #1e579900 0%, #262835 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e579900', endColorstr='#262835', GradientType=0);
    /* IE6-9 */
}

.game-info {
    max-height: 4.75em;
    /* opacity: 0; */
    transition: all .3s ease-in-out;
    display: none;
}

.game-description {
    opacity: 0.5;
}

.game-info--open .game-info {
    max-height: unset;
    /* opacity: 1; */
    display: block;
}

.game-info--open .game-info .btn-more {
    display: none;
}

.game-info--open .game-description {
    opacity: 1;
}

.custom-modal {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#feda3e+0,f8b81a+100 */
    background: #feda3e;
    /* Old browsers */
    background: -moz-linear-gradient(top, #feda3e 0%, #f8b81a 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #feda3e 0%, #f8b81a 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #feda3e 0%, #f8b81a 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#feda3e', endColorstr='#f8b81a', GradientType=0);
    /* IE6-9 */
    border-radius: 20px;
    border: 5px solid #ef8405;
    box-shadow: inset 0px 0px 1px 3px #da820d;
}

.bg-darkarea {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#962f01+0,9f3e01+33,af4e01+100 */
    background: #962f01;
    /* Old browsers */
    background: -moz-linear-gradient(top, #962f01 0%, #9f3e01 33%, #af4e01 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #962f01 0%, #9f3e01 33%, #af4e01 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #962f01 0%, #9f3e01 33%, #af4e01 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#962f01', endColorstr='#af4e01', GradientType=0);
    /* IE6-9 */
    border-radius: 20px;
    padding: 15px;
    border: 2px solid #ef8405;
    box-shadow: inset 0px 0px 1px 5px #f6bd3b;
}

.game-item {
    transition: all .3s ease-in-out;
    width: 50%;
}


.game-info--open.game-item {
    width: 100%;
}

.game-item-bg {
    width: 100%;
    transition: all .3s ease-in-out;
}

.game-info--open.game-item .game-item-bg {
    width: 50%;
}

.game-item:nth-child(odd) {
    padding-right: .4em;
}

.game-item:nth-child(even) {
    padding-left: .4em;
}

.game-item .card {
    border: 0;
    background: transparent;
}

.game-item .game-item-bg {
    background: url(./ui/bg-sm-circle.png);
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: left center;
    border: 0;
    min-height: 110%;
    padding: 5% 0;
}

.loading-overlay {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}